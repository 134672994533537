<template>
  <div class="max-w-7xl mx-auto font-poppins">
    <div class="w-full">
       <nav class="py-5 px-4 flex items-center bg-white">
          <h3 class="text-20 leading-30 font-medium text-primary-dark">Survey Kepuasan Pelanggan</h3>
        </nav>

      <div class="">
        <div class="bg-white pb-4">
          <BannerHeader/>
        </div>
        
        <div class="">
          <ValidationObserver ref="form">
            <div v-if="validates.length" class="bg-white px-4 py-3">
              <div class="bg-danger-300">
                <ul class="list-disc mx-5 px-4 font-10">
                  <li class="text-danger text-12 leading-18 py-2" v-for="err in validates" :key="err.name">{{ err.message }}</li>
                </ul>
              </div>
            </div>
            <form class="">
              <div id="survey" class="bg-white px-4 pb-1">
                <label class="text-16 leading-21 text-primary-dark">
                  Nomor Polisi
                </label>
                <div class="mb-4">
                  <ValidationProvider name="Nomor polisi" rules="required" v-slot="{ errors }">
                    <input 
                      disabled
                      v-model="police_number"
                      class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                      type="text" 
                      autocomplete="off"
                      placeholder="Masukkan nomor polisi">
                    <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>
              </div>
            
            </form>
          </ValidationObserver>
        </div>

        <div class="px-5 pt-3 pb-5 bg-white">
          <hr class="border-grey">
        </div>

        <div class="">
          <ValidationObserver ref="form">
            <form class="" @submit.prevent="handleSubmit">
              <div id="survey" class="bg-white px-4 pb-1"
                v-for="survey in surveys"
                :key="survey.id" >
                <div class="mb-4">
                  <label class="text-16 leading-24 font-medium text-primary-dark">
                    {{ survey.question }}
                  </label>
                </div>

                <div>
                  <div v-if="survey.type == 'all'">

                    <div class="mt-4 mb-5">
                      <ValidationProvider :name="'Rating '+survey.question" rules="required" v-slot="{ errors }">
                        <star-rating 
                          v-model="surveys[getIndex(surveys, survey.id)].rating"
                          :star-size="35"
                          inactive-color="#fff"
                          active-color="#086dc7"
                          :border-width="3"
                          active-border-color="#086dc7"
                          :rounded-corners="true"
                          :show-rating="false"
                        ></star-rating>
                        <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>

                    <label class="text-16 leading-21 text-primary-dark">
                      Komentar Anda
                    </label>
                    <div class="">
                      <ValidationProvider :name="'Komentar '+survey.question" rules="required" v-slot="{ errors }">
                        <textarea 
                          name="comment" 
                          v-model="surveys[getIndex(surveys, survey.id)].comment"
                          class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2"
                          rows="5"
                          placeholder="Tuliskan komentar Anda disini...">
                        </textarea>
                        <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>

                  </div>
                  <div v-if="survey.type == 'rating'">
                    <div class="mt-4 mb-5">
                      <ValidationProvider :name="'Rating '+survey.question" rules="required" v-slot="{ errors }">
                        <star-rating 
                          v-model="surveys[getIndex(surveys, survey.id)].rating"
                          :star-size="35"
                          inactive-color="#fff"
                          active-color="#086dc7"
                          :border-width="3"
                          active-border-color="#086dc7"
                          :rounded-corners="true"
                          :show-rating="false"
                        ></star-rating>
                        <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div v-if="survey.type == 'description'">

                    <label class="text-16 leading-21 text-primary-dark">
                      Komentar Anda
                    </label>
                    <div class="">
                      <ValidationProvider :name="'Komentar '+survey.question" rules="required" v-slot="{ errors }">
                        <textarea 
                          name="comment" 
                          v-model="surveys[getIndex(surveys, survey.id)].comment"
                          class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2"
                          rows="5"
                          placeholder="Tuliskan komentar Anda disini...">
                        </textarea>
                        <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div v-if="survey.type == 'choice'">

                   <div class="mt-4 mb-5">
                      <ValidationProvider :name="'Pilihan '+survey.question" rules="required" v-slot="{ errors }">
                        <div class="flex">
                          <div class="mr-4">
                            <input type="radio" :name="'choice'+survey.id" class="mr-2" v-model="surveys[getIndex(surveys, survey.id)].pilihan" value="Yes">
                            <label for="yes" class="text-16 leading-24 font-medium text-primary-dark">Yes</label>
                          </div>

                          <div class="mr-4">
                            <input type="radio" :name="'choice'+survey.id" class="mr-2" v-model="surveys[getIndex(surveys, survey.id)].pilihan" value="No">
                            <label for="no" class="text-16 leading-24 font-medium text-primary-dark">No</label>
                          </div>
                        </div>
                        <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>

                    <div v-if="surveys[getIndex(surveys, survey.id)].pilihan == 'Yes'">
                      <label class="text-16 leading-21 text-primary-dark">
                        Komentar Anda
                      </label>
                      <div class="">
                        <ValidationProvider :name="'Komentar '+survey.question" rules="required" v-slot="{ errors }">
                          <textarea 
                            name="comment" 
                            v-model="surveys[getIndex(surveys, survey.id)].comment"
                            class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2"
                            rows="5"
                            placeholder="Tuliskan komentar Anda disini...">
                          </textarea>
                          <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="px-5 py-5 bg-white">
                  <hr class="border-grey">
                </div>

              </div>

              <div class="grid text-center bg-white pt-1 pb-5 px-4">
                <button 
                  class="bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 px-20 rounded-4 inline-block focus:outline-none focus:shadow-outline" 
                  type="submit">
                  Submit
                </button>
              </div>            
            
            </form>
          </ValidationObserver>
        </div>

      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
const successImg = require("@/assets/icons/tick-square.png");
import StarRating from 'vue-star-rating'
import BannerHeader from "../../components/BannerHeader.vue"


export default {
  props:['id'],
  components:{
    StarRating,
    BannerHeader
  },
  name: 'surveyDetail',
  metaInfo: {
    title: 'Hyundai Service Point',
    titleTemplate: '%s - Survey Kepuasan Pelanggan',
  },
  data(){
    return {
      police_number:this.id,
      booking:null,
      surveys:[],
      errors:[],
      validates:[],
    }
  },
  watch: {},
  methods: {
    getIndex(list, id) {
      return list.findIndex((e) => e.id == id)
    },
    getBookingSurvey() {
      const token = localStorage.getItem('jwt');
      if(token) {
        axios.get(`${process.env.VUE_APP_API_URL}api/survey/check-booking-survey?police_number=${this.id}`,
        { headers: 
          { 'access-token': token}
        })
        .then(response => {
          const { data, status } = response.data
          if(status == 200) {
            this.booking = data
          }
        })
        .catch(e => {
          console.log(e.response);
          if(e.response) {
            const {status, message} = e.response.data
            if(status == 404) {
              this.$swal({
                icon: 'error',
                html:
                  '<h2 class="text-20 leading-30 text-primary-dark font-medium mb-4">Error</h2>' +
                  '<p class="text-16 leading-24 text-placeholder mb-0">'+message+'</p>',
                  customClass: {
                    confirmButton: 'bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 sm:px-52 px-16 rounded-4 inline-block focus:outline-none focus:shadow-outline',
                  },
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.$router.push("/survey");
                } else if (result.isDenied) {
                  this.$router.push("/survey");
                }else{
                  this.$router.push("/survey");
                }
              })
            }
            if(status == 401){
              localStorage.removeItem('jwt');
              localStorage.removeItem('user');
              this.$router.push({
                name: 'login',
              })
            }
          }
        })
      }else{
        this.$router.push('/login')
      }
    },
    getQuestionSurvey() {
      const token = localStorage.getItem('jwt');
      if(token) {
        axios.get(`${process.env.VUE_APP_API_URL}api/survey/question`,
        { headers: 
          { 'access-token': token}
        })
        .then(response => {
          const { data, status } = response.data
          if(status == 200) {
            this.surveys = data
          }
        })
        .catch(e => {
          console.log(e.response);
          if(e.response) {
            const {status} = e.response.data
            if(status == 401){
              localStorage.removeItem('jwt');
              localStorage.removeItem('user');
              this.$router.push({
                name: 'login',
              })
            }
          }
        })
      }else{
        this.$router.push('/login')
      }
    },
    handleSubmit() {

      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });

        const token = localStorage.getItem('jwt');
        if(token) {
          const dataSurvey = {}
          dataSurvey.booking_id = this.booking.id

          const answers = this.surveys.map(val => { 
            return { question_survey_id: val.id, rating: val && val.rating ? val.rating : '', description: val && val.comment ? val.comment : '', choice: val && val.pilihan ? val.pilihan : ''}
          })

          dataSurvey.answer_payload = answers
          axios.post(`${process.env.VUE_APP_API_URL}api/survey/store`,
          qs.stringify(dataSurvey),
            { headers: 
              { 'access-token': token}
            })
          .then(response => {
            const { status } = response.data
            if(status == 200) {
              this.$swal({
                imageUrl: successImg,
                html:
                '<h2 class="text-20 leading-30 text-primary-dark font-medium mb-4">Sukses</h2>' +
                '<p class="text-16 leading-24 text-placeholder mb-0">Survey kepuasan pelanggan sukses terkirim.</p>' +
                '<p class="text-16 leading-24 text-placeholder">Terimakasih.</p>',
                customClass: {
                  confirmButton: 'bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 sm:px-52 px-16 rounded-4 inline-block focus:outline-none focus:shadow-outline',
                },
                buttonsStyling: false,
                showCancelButton: false,
                confirmButtonText: 'Tutup',
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.$router.push("/survey");
                } else if (result.isDenied) {
                  this.$router.push("/survey");
                }else{
                  this.$router.push("/survey");
                }
              })
            }
          })
          .catch(e => {
            console.log(e.response);
            if(e.response) {
              const {status} = e.response.data
              if(status == 401){
                localStorage.removeItem('jwt');
                localStorage.removeItem('user');
                this.$router.push({
                  name: 'login',
                })
              }
            }
          })
        }else{
          this.$router.push('/login')
        }
      })
    }
  },
  mounted() {
    this.getBookingSurvey();
    this.getQuestionSurvey();
  }
}
</script>

<style>
  
</style>