var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-7xl mx-auto font-poppins"},[_c('div',{staticClass:"w-full"},[_c('nav',{staticClass:"py-5 px-4 flex items-center bg-white"},[_c('h3',{staticClass:"text-20 leading-30 font-medium text-primary-dark"},[_vm._v("Filter Vehicle - "+_vm._s(_vm.booking && _vm.booking.booking_type == 'Online Booking' ? 'Booking Online' : 'Walk In'))])]),_c('div',{},[(_vm.booking && _vm.booking.booking_type == 'Online Booking')?_c('div',{staticClass:"info px-4 bg-white pt-2"},[_c('div',{staticClass:"bg-primary-light-3 px-3 py-3 text-12 leading-18 text-primary rounded-4"},[_vm._v(" Selamat datang Bapak / Ibu "+_vm._s(_vm.booking.customer_name)+" pemilik kendaraan "+_vm._s(_vm.booking.vehicle.brand.name)+" "+_vm._s(_vm.booking.vehicle.brand == 'Hyundai' ? _vm.booking.vehicle.model.name : _vm.booking.vehicle.model_name)+" dengan Nomor Polisi "+_vm._s(_vm.booking.vehicle.police_number)+" telah melakukan reservasi service pada tanggal "+_vm._s(_vm.moment(_vm.booking.date).format('LL'))+" pada pukul "+_vm._s(_vm.booking.time)+" ")])]):_vm._e(),_c('div',{staticClass:"regist_form"},[_c('ValidationObserver',{ref:"form"},[(_vm.validates.length)?_c('div',{staticClass:"bg-white px-4 py-3"},[_c('div',{staticClass:"bg-danger-300"},[_c('ul',{staticClass:"list-disc mx-5 px-3 font-10"},_vm._l((_vm.validates),function(err){return _c('li',{key:err.name,staticClass:"text-danger text-12 leading-18 py-2"},[_vm._v(_vm._s(err.message))])}),0)])]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleRegist.apply(null, arguments)}}},[_c('div',{staticClass:"bg-white px-4 pt-4 pb-2",attrs:{"id":"regist"}},[_c('label',{staticClass:"text-16 leading-21 text-primary-dark"},[_vm._v(" Nomor Polisi ")]),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"Nomor Polisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.police_number),expression:"data.police_number"}],staticClass:"appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2",attrs:{"disabled":"","type":"text","autocomplete":"off","placeholder":"Masukkan no polisi"},domProps:{"value":(_vm.data.police_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "police_number", $event.target.value)}}}),_c('p',{staticClass:"text-danger text-12 leading-18 mt-2"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.booking && !_vm.booking.booking_type == 'Online Booking' || _vm.booking == null)?_c('div',[_c('label',{staticClass:"text-16 leading-21 text-primary-dark"},[_vm._v(" Nama Lengkap ")]),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"Nama lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.nama_lengkap),expression:"data.nama_lengkap"}],staticClass:"appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2",attrs:{"type":"text","autocomplete":"off","placeholder":"Masukkan nama lengkap"},domProps:{"value":(_vm.data.nama_lengkap)},on:{"keypress":function($event){return _vm.isLetterOrNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "nama_lengkap", $event.target.value)}}}),_c('p',{staticClass:"text-danger text-12 leading-18 mt-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,25842258)})],1),_c('label',{staticClass:"text-16 leading-21 text-primary-dark"},[_vm._v(" No WhatsApp ")]),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{staticClass:"relative",attrs:{"name":"Nomor whatsapp","rules":"required|phone_format|minimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.no_whatsapp),expression:"data.no_whatsapp"}],staticClass:"appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-4 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2",attrs:{"type":"text","inputmode":"numeric","pattern":"[0-9]+","autocomplete":"off","placeholder":"Masukkan no WhatsApp"},domProps:{"value":(_vm.data.no_whatsapp)},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "no_whatsapp", $event.target.value)}}}),_c('p',{staticClass:"text-danger text-12 leading-18 mt-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1128385417)})],1)]):_vm._e(),_c('label',{staticClass:"text-16 leading-21 text-primary-dark"},[_vm._v(" Odometer ")]),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"Odometer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.odometer),expression:"data.odometer"}],staticClass:"appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2",attrs:{"type":"text","autocomplete":"off","placeholder":"Masukkan jarak tempuh kendaraan"},domProps:{"value":(_vm.data.odometer)},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "odometer", $event.target.value)}}}),_c('p',{staticClass:"text-danger text-12 leading-18 mt-2"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"grid text-center bg-white px-4"},[_c('button',{staticClass:"bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 px-20 rounded-4 inline-block focus:outline-none focus:shadow-outline",attrs:{"disabled":_vm.loading,"type":"submit"}},[(_vm.loading)?_c('span',[_vm._v("Proses...")]):_c('span',[_vm._v("Print Registrasi")])])])])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }