<template>
  <div class="h-screen w-full">
    <div class="flex items-center pt-32 px-32">
      <div class="404">
        <h2 class="text-32">OOPS, 404</h2>
        <h3>Page Not Found</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notFound',
  metaInfo: {
    title: 'Hyundai Service Point',
    titleTemplate: '%s - Not Found',
  },
}
</script>

<style>

</style>