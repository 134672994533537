<template>
  <div class="max-w-7xl mx-auto font-poppins">
    <div class="w-full">
       <nav class="py-5 px-4 flex items-center bg-white">
          <h3 class="text-20 leading-30 font-medium text-primary-dark">Filter Vehicle</h3>
        </nav>

      <div class="">
        <div class="bg-white">
          <BannerHeader/>
        </div>
        
        <div class="">
          <ValidationObserver ref="form">
            <div v-if="validates.length" class="bg-white px-4 py-3">
              <div class="bg-danger-300">
                <ul class="list-disc mx-5 px-4 font-10">
                  <li class="text-danger text-12 leading-18 py-2" v-for="err in validates" :key="err.name">{{ err.message }}</li>
                </ul>
              </div>
            </div>
            <form class="" @submit.prevent="handleLogin">
              <div id="login" class="bg-white px-4 pt-4 pb-2">
                <label class="text-16 leading-21 text-primary-dark">
                  Username
                </label>
                <div class="mb-4">
                  <ValidationProvider name="Username" rules="required" v-slot="{ errors }">
                    <input 
                      v-model="username"
                      class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                      type="text" 
                      autocomplete="off"
                      placeholder="Masukkan username Anda">
                    <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>

                <label class="text-16 leading-21 text-primary-dark">
                Password
                </label>
                <div class="mb-4">
                  <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                    <input 
                      v-model="password"
                      class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                      type="password" 
                      autocomplete="off"
                      placeholder="Masukkan password">
                    <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>
                <div class="mb-4">
                  <label class="text-16 leading-21 text-primary-dark">
                  Lokasi
                  </label>
                  <ValidationProvider name="Lokasi" rules="required" v-slot="{ errors }">
                    <v-select 
                      v-model="event"
                      :options="locations"
                      class="font-normal text-14 leading-24 text-primary-dark mt-2"
                      placeholder="Pilih lokasi event">
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Lokasi <em>{{ search }}</em> tidak tersedia.
                        </template>
                        <em v-else style="opacity: 0.5">Lokasi tidak tersedia</em>
                      </template>
                    </v-select>
                    <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>
              </div>
            
              <div class="grid text-center bg-white py-3 px-4">
                <button 
                  class="bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 px-20 rounded-4 inline-block focus:outline-none focus:shadow-outline" 
                  type="submit">
                  Login
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>

      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from "vue-select"
import BannerHeader from "../components/BannerHeader.vue"
import 'vue-select/dist/vue-select.css'

export default {
  components:{
    vSelect,
    BannerHeader,
  },
  name: 'login',
  metaInfo: {
    title: 'Hyundai Service Point',
    titleTemplate: '%s - Login',
  },
  data(){
    return {
      username:"",
      password:"",
      event:null,
      data:{},
      locations:[],
      errors:[],
      validates:[],
    }
  },
  watch: {},
  methods: {
    getEventList() {
      axios.get(`${process.env.VUE_APP_API_URL}api/event/location`)
      .then(response => {
        const { data } = response.data
        this.locations = data.map(item => { 
          return { label: item.venue, value: item.id, start_date: item.start_date, end_date: item.end_date, call_center: item.call_center }
        })
      })
      .catch(e => {
        if(e.response) {
          console.log(e.response.message)
        }
      })
    },
    handleLogin() {

      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });

        var formData = new FormData();
        formData.append('username', this.username);
        formData.append('password', this.password);
        formData.append('event_id', this.event.value);

        axios.post(`${process.env.VUE_APP_API_URL}api/auth/login`,
          formData,
          { headers: { 'content-type': `multipart/form-data; boundary=${formData._boundary}`}}
        ).then(response => {
          const {data, status} = response.data
          if(status == 200) {
            let is_doorman = data.role
            localStorage.setItem('user',JSON.stringify(data))
            localStorage.setItem('jwt',data.token)

            if (localStorage.getItem('jwt') != null) {
              this.$emit('loggedIn')
              if (this.$route.params.nextUrl != null) {
                this.$router.push(this.$route.params.nextUrl)
              } else {

                if (is_doorman == "Doorman") {
                  this.$router.push('/vehicle')
                } else {
                  this.$router.push('/')
                }
              }
            }
          } else {
            alert('error login')
          }

        })
        .catch(e => {
          if(e.response) {
            const {data, status, message} = e.response.data
            if(status == 422) {
              this.validates = data
            }else if(status == 401){
              this.validates = [{message}]
            }
          }
        })

      });
      
    }
  },
  mounted() {
    this.getEventList()
  }
}
</script>

<style>
  
</style>