<template>
  <div class="max-w-7xl mx-auto font-poppins">
    <div class="w-full">
       <nav class="py-5 px-4 flex items-center bg-white">
          <h3 class="text-20 leading-30 font-medium text-primary-dark">Filter Vehicle</h3>
        </nav>

      <div class="">
        
        <div class="search">
          <ValidationObserver ref="form">
            <div v-if="validates.length" class="bg-white px-4 py-3">
              <div class="bg-danger-300">
                <ul class="list-disc mx-5 px-3 font-10">
                  <li class="text-danger text-12 leading-18 py-2" v-for="err in validates" :key="err.name">{{ err.message }}</li>
                </ul>
              </div>
            </div>
            <form class="" @submit.prevent="handleSearch">
              <div id="search" class="bg-white px-4 pt-4 pb-2">
                <label class="text-16 leading-21 text-primary-dark">
                  Nomor Polisi
                </label>
                <div class="mb-4">
                  <ValidationProvider name="Nomor Polisi" rules="required" v-slot="{ errors }">
                    <input 
                      v-model="police_number"
                      @keypress="isLetterOrNumber($event)"
                      @input="police_number = $event.target.value.toUpperCase()"
                      class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                      type="text" 
                      autocomplete="off"
                      placeholder="Masukkan no polisi">
                    <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>
              </div>
              <div class="grid text-center bg-white px-4">
                <button 
                  class="bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 px-20 rounded-4 inline-block focus:outline-none focus:shadow-outline" 
                  type="submit">
                  Cari
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div class="px-4 py-7 bg-white">
          <hr class="border-grey">
        </div>

        <div class="list_booking px-4 bg-white">
          <div class="mb-5">
            <h3 class="text-16 leading-24 font-medium text-primary-dark">List Booking</h3>
          </div>
          
          <div v-if="list_booking">
            <div class="flex items-center justify-between text-placeholder text-14 leading-21">
              <span>Jam</span>
              <span>Booking</span>
            </div>
            <div v-for="(booking, index) in list_booking" :key="index">
              <Accordion :time="index" :booking="booking.length+' Booking'" >

                <div v-for="detail in booking" :key="detail.id"> 
                  <div class="flex items-start">
                    <div class="mr-3">
                        <span class="rounded-full bg-black w-2 h-2 inline-block mt-6"></span>
                    </div>
                    <div class="flex flex-wrap">
                      <div class="sm:w-44 w-32 py-3">
                        <label class="text-14 leading-21 font-medium text-primary-dark">Nomor Polisi</label>
                        <div class="text-14 leading-21 font-normal text-placeholder">{{detail.vehicle.police_number}}</div>
                      </div>
                      <div class="sm:w-44 w-32 py-3">
                        <label class="text-14 leading-21 font-medium text-primary-dark">Model/Type</label>
                        <div class="text-14 leading-21 font-normal text-placeholder">{{detail.vehicle.brand.name}} 
                          <span v-if="detail.vehicle.brand.name == 'Hyundai'">
                            {{detail.vehicle.model?detail.vehicle.model.name:detail.vehicle.model_name}}
                          </span>
                          <span v-else>
                            {{detail.vehicle.model_name}}
                          </span>
                        </div>
                      </div>
                      <div class="sm:w-44 w-32 py-3">
                        <label class="text-14 leading-21 font-medium text-primary-dark">Nama Customer</label>
                        <div class="text-14 leading-21 font-normal text-placeholder">{{detail.customer_name}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 py-3 bg-white1">
                    <hr class="border-grey">
                  </div>

                </div>

              </Accordion>
            </div>
          </div>
          <div v-else>
            <p class="text-center text-placeholder border-2 border-grey rounded-4 mt-2 text-14 leading-21 p-2">
              Belum ada booking services!
            </p>
          </div>

        </div>

      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import regex from "../../libs/regex"
import Accordion from '../../components/Accordion.vue'

export default {
  components:{
    Accordion
  },
  name: 'vehicle',
  metaInfo: {
    title: 'Hyundai Service Point',
    titleTemplate: '%s - Filter Vehicle',
  },
  data(){
    return {
      police_number:"",
      list_booking:null,
      validates:[],
    }
  },
  watch: {},
  methods: {
    listBooking(){
      const token = localStorage.getItem('jwt');
      if(token) {
        axios.get(`${process.env.VUE_APP_API_URL}api/booking/list`,
        {headers: { "access-token": token}}
        )
        .then(response => {
          const { data, status } = response.data
          if(status == 200) {
            this.list_booking = data
          }
        })
        .catch(e => {
          console.log(e.response);
          if(e.response) {
            const {status, message} = e.response.data
            if(status == 404) {
              // this.validates = [{message}]
              console.log(message);
            }else if(status == 401){
              localStorage.removeItem('jwt');
              localStorage.removeItem('user');
              this.$router.push({
                name: 'login',
              })
            }
          }
        })
      }else{
        this.$router.push('/login')
      }
    },
    handleSearch(){
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });

         this.$router.push({
          name: 'vehicleBooking',
          params: {
            id: this.police_number
          }
        })
      })
    },
    isLetterOrNumber(e) {
      regex.isLetterOrNumber(e)
    },
  },
  mounted() {
    this.listBooking();
  }
}
</script>

<style>
  .list-car{
    width: 100px;
  }
</style>
