<template>
  <div>
    <!-- Main modal -->
    <div 
      v-if="isBanner"
      id="bannerModal" 
      aria-hidden="true" 
      class="z-40 fixed inset-0 flex items-center justify-center bg-semi-black overflow-y-auto overflow-x-hidden">
      <div class="relative px-4 w-full max-w-4xl sm:max-w-2xl  md:h-auto">
          <!-- Modal content -->
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 rounded-4">
              <!-- Modal header -->
              <div class="flex justify-end items-center rounded-t px-5 pt-1">
                  <img :src="require(`@/assets/hyundai.png`)" class="h-10 sm:h-16" alt="">
                  <button 
                    type="button"  
                    v-on:click="isBanner = !isBanner"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                      <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
                  </button>
              </div>
              <!-- Modal body -->
              <div class="p-6 overflow-auto max-h-half">
                <h4 class="text-16 font-semibold text-black mb-5">{{ title }}</h4>

                <slot></slot>

                <!-- <div class="flex flex-wrap justify-between sm:space-x-3 sm:space-y-1 space-x-0">
                  <div class="border-r border-b border-primary rounded-10 pt-1 px-1 sm:px-2 ">
                    <div class="flex space-x-1 sm:space-x-2">
                      <div class="w-7 xs:w-9 2xs:w-11 sm:w-14 flex flex-col">
                        <div class="text-service  2xs:text-7 sm:text-9 text-center text-primary-dark mb-1 flex-1">SCANNING<br>HYUNDAI_GDS</div>
                        <img class="w-full" :src="img" alt="">
                      </div>
                      <div class="w-7 xs:w-9 2xs:w-11 sm:w-14 flex flex-col">
                        <div class="text-service  2xs:text-7 sm:text-9 text-center text-primary-dark mb-1 flex-1">2L OLI<br>(for oil change)</div>
                        <img class="w-full" :src="img" alt="">
                      </div>
                      <div class="w-7 xs:w-9 2xs:w-11 sm:w-14 flex flex-col">
                        <div class="text-service  2xs:text-7 sm:text-9 text-center text-primary-dark mb-1 flex-1">20% OFF<br>(next service)</div>
                        <img class="w-full" :src="img" alt="">
                      </div>
                      <div class="w-7 xs:w-9 2xs:w-11 sm:w-14 flex flex-col">
                        <div class="text-service  2xs:text-7 sm:text-9 text-center text-primary-dark mb-1 flex-1">20% OFF<br>(next service)</div>
                        <img class="w-full" :src="img" alt="">
                      </div>
                      <div class="w-7 xs:w-9 2xs:w-11 sm:w-14 flex flex-col">
                        <div class="text-service  2xs:text-7 sm:text-9 text-center text-primary-dark mb-1 flex-1">20% OFF<br>(next service)</div>
                        <img class="w-full" :src="img" alt="">
                      </div>
                    </div>
                    
                    <p class="text-7 sm:text-9 pt-1 text-right text-primary-dark">APPLICABLE TO ALL CARBRANDS</p>
                  </div>
                  <div class="border-l border-b border-primary rounded-10 pt-1 px-1 sm:px-2 ">
                    <div class="flex space-x-1 sm:space-x-2">
                      <div class="w-7 xs:w-9 2xs:w-11 sm:w-14 flex flex-col">
                        <div class="text-service  2xs:text-7 sm:text-9 text-center text-primary-dark mb-1 flex-1">SCANNING<br>HYUNDAI_GDS</div>
                        <img class="w-full" :src="img" alt="">
                      </div>
                      <div class="w-7 xs:w-9 2xs:w-11 sm:w-14 flex flex-col">
                        <div class="text-service  2xs:text-7 sm:text-9 text-center text-primary-dark mb-1 flex-1">2L OLI<br>(for oil change)</div>
                        <img class="w-full" :src="img" alt="">
                      </div>
                      <div class="w-7 xs:w-9 2xs:w-11 sm:w-14 flex flex-col">
                        <div class="text-service  2xs:text-7 sm:text-9 text-center text-primary-dark mb-1 flex-1">20% OFF<br>(next service)</div>
                        <img class="w-full" :src="img" alt="">
                      </div>
                    </div>
                    
                    <p class="text-7 sm:text-9 pt-1 text-primary-dark">HYUNDAI CARS ONLY</p>
                  </div>

                </div>

                <div class="flex justify-between mt-3">  
                  <div class="">
                    <p class="text-7 sm:text-9">All brands are welcome to avail of services at Hyundai Service Point.</p>
                    <p class="text-7 sm:text-9">of services at Hyundai Service Point.</p>
                  </div>
                   <div class="text-right">
                    <p class="text-7 sm:text-9">**Available only in DKI Jakarta</p>
                    <p class="text-7 sm:text-9">**Available in certain location</p>
                  </div>
                </div>
                 -->
              </div>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
const service = require("@/assets/service.png");

export default {
  data(){
    return {
      isBanner: false,
      img: service
    }
  },
  name: 'Accordion',
  props: {
      title: String,
  },
  mounted: function() {
    setTimeout(()=>{
      this.isBanner = true
    },2000);
  },
}
</script>

<style>
  .max-h-half {
    max-height: 70vh;
  }
  .text-service {
    font-size: .3rem;
  }

  @media only screen and (min-width: 600px) {
  .max-h-half {
    max-height: 85vh;
  }
}
</style>