<template>
  <div class="max-w-7xl mx-auto text-gray-600">
    <div class="w-full">
      <div class="bg-white px-4 py-4 mb-2">
        <h2 class="text-16 leading-24 text-primary-dark font-medium mb-5">Reservasi Detail</h2>
        <div v-if="reservasi && reservasi.booking_type == 'Online Booking'">
          <p class="mb-4">
            Nama : <span class="font-bold">{{ reservasi.customer_name }}</span> <br/> 
            Tanggal Booking : <span class="font-bold"> {{ moment(reservasi.date).format('LL')}} </span> <br/> 
          </p>
          <div class="mb-4">
            <p>
              No. Polisi : 
              <span class="font-bold">{{ reservasi.vehicle.police_number }}</span> 
              <span class="font-bold">{{ reservasi.vin ? '/' +  reservasi.vin : '' }}</span> 
            </p>
            <p>
              Brand : 
              <span class="font-bold">{{ reservasi.vehicle.brand ? reservasi.vehicle.brand.name : '' }}</span> 
              <span class="font-bold">{{ reservasi.vehicle.model ? (reservasi.vehicle.model_name ? ' / '+reservasi.vehicle.model_name : ' / '+reservasi.vehicle.model.name) : (reservasi.vehicle.model_name ? ' / '+reservasi.vehicle.model_name : '') }}</span> 
            </p>
            <p>
              Odometer : 
              <span class="font-bold">{{ reservasi.odometer ? reservasi.odometer+' KM' : '-' }} </span> 
            </p>
          </div>

          <span>Permintaan Service :</span>
          <div class="pl-4 mb-4">
            <ol class="list-decimal">
              <li class="pl-3" v-for="(service, index) in reservasi.service_name" :key="index">{{service}}</li>
            </ol>
          </div>

          <p>Silakan melakukan pendaftaran service melalui Service Advisor kami.</p> 

          <p class="mb-4">
            Call Center : <a class="href" target="_blank" :href="'https://' + formatWhatssapNumber(reservasi.event.call_center)">
            {{ reservasi.event.call_center }}</a>
          </p>

        </div>

        <div v-else-if="reservasi && reservasi.booking_type == 'Walk In'">
           <p class="mb-4">
            Nama : <span class="font-bold">{{ reservasi.customer_name }}</span> <br/> 
          </p>
          <div class="mb-4">
            <p>
              No. Polisi : 
              <span class="font-bold">{{ reservasi.vehicle.police_number }}</span> 
            </p>
            <p>
              Odometer : 
              <span class="font-bold">{{ reservasi.odometer ? reservasi.odometer+' KM' : '-' }} </span> 
            </p>
          </div>
          <p>Silakan melakukan pendaftaran service melalui Service Advisor kami.</p>

          <p class="mb-4">
            Call Center : <a class="href" target="_blank" :href="'https://' + formatWhatssapNumber(reservasi.event.call_center)">
            {{ reservasi.event.call_center }}</a>
          </p>

        </div>

        <div v-else>
          <p class="py-10">Data reservasi tidak ada</p>
        </div>

      </div>
        <div class="grid text-center bg-white py-5 my-2 px-4">
          <button 
            @click="booking()"
            class="bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 px-20 rounded-4 inline-block focus:outline-none focus:shadow-outline" 
            type="button"> Form Booking
          </button>
        </div>
     
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  props:['id'],

  name: 'reservasiDetail',
  metaInfo: {
    title: 'Hyundai Service Point',
    titleTemplate: '%s - Reservasi Detail',
  },
  data() {
    return {
      reservasi:{}
    }
  },
  methods: {
    formatWhatssapNumber(number){
      if(number.substring(0, 2) == '08') {
        number = "62"+number.substring(1)
      }
      return number = 'wa.me/'+number
    },
    moment: function (date) {
      return moment(date).locale('id');
    },
    getDetailReservasi() {
      axios.get(`${process.env.VUE_APP_API_URL}api/booking/detail?booking_id=${this.id}`)
      .then(response => {
        const { data, status } = response.data
        if(status == 200) {
          this.reservasi = data
        }
      })
      .catch(e => {
        if(e.response) {
          const {status, message} = e.response.data
          if(status == 404) {
            console.log(message);
            this.$router.push("/");
          }
          if(status == 401){
            localStorage.removeItem('jwt');
            localStorage.removeItem('user');
            this.$router.push({
              name: 'login',
            })
          }
        }
       
      })
    },
    booking() {
      this.$router.push("/");
    }
  },
  mounted() {
    this.getDetailReservasi()
  }
}
</script>

<style scoped>
.href {
  color: #1a0dab;
}
.href:hover {
  color: #1f0fc5;
}
</style>