<template>
  <div>
      <div v-for="i in [currentIndex]" :key="i">
          <img class="" :src="envapi + 'storage/' +currentImg.url" />
      </div>
    <!-- <a class="prev" @click="prev" href="#">&#10094; Previous</a>
    <a class="next" @click="next" href="#">&#10095; Next</a> -->
  </div>
</template>
<script>
export default {
  name: "Slider",
  data() {
    return {
      timer: null,
      currentIndex: 0,
      envapi: process.env.VUE_APP_API_URL,
    };
  },
props: {
    images: Array,
},

  mounted: function() {
    this.startSlide();
  },

  methods: {
    startSlide: function() {
      if(this.images.length > 1) {
        this.timer = setInterval(this.next, 7000);
      }
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    }
  },

  computed: {
    currentImg: function() {
      return this.images[this.currentIndex % this.images.length];
    }
  }
};
</script>
<style scoped>

img {
    width: 100%;
    /* max-height: 400px; */
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.9);
}
</style>