<template>
  <div class="max-w-7xl mx-auto font-poppins">
    <div class="w-full">
       <nav class="py-5 px-4 flex items-center bg-white">
          <h3 class="text-20 leading-30 font-medium text-primary-dark">Filter Vehicle - {{booking && booking.booking_type == 'Online Booking' ? 'Booking Online' : 'Walk In'}}</h3>
        </nav>

      <div class="">
        
        <div v-if="booking && booking.booking_type == 'Online Booking'" class="info px-4 bg-white pt-2">
          <div class="bg-primary-light-3 px-3 py-3 text-12 leading-18 text-primary rounded-4">
            Selamat datang Bapak / Ibu {{booking.customer_name}} pemilik kendaraan {{booking.vehicle.brand.name}} {{booking.vehicle.brand == 'Hyundai' ? booking.vehicle.model.name : booking.vehicle.model_name}} dengan Nomor Polisi {{booking.vehicle.police_number}} telah melakukan reservasi service pada tanggal {{moment(booking.date).format('LL')}} pada pukul {{booking.time}}
          </div>
        </div>
        
        <div class="regist_form">
          <ValidationObserver ref="form">
            <div v-if="validates.length" class="bg-white px-4 py-3">
              <div class="bg-danger-300">
                <ul class="list-disc mx-5 px-3 font-10">
                  <li class="text-danger text-12 leading-18 py-2" v-for="err in validates" :key="err.name">{{ err.message }}</li>
                </ul>
              </div>
            </div>
            <form class="" @submit.prevent="handleRegist">
              <div id="regist" class="bg-white px-4 pt-4 pb-2">
                <label class="text-16 leading-21 text-primary-dark">
                  Nomor Polisi
                </label>
                <div class="mb-4">
                  <ValidationProvider name="Nomor Polisi" rules="required" v-slot="{ errors }">
                    <input 
                      disabled
                      v-model="data.police_number"
                      class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                      type="text" 
                      autocomplete="off"
                      placeholder="Masukkan no polisi">
                    <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>

                <div v-if="booking && !booking.booking_type == 'Online Booking' || booking == null">

                  <label class="text-16 leading-21 text-primary-dark">
                  Nama Lengkap
                  </label>
                  <div class="mb-4">
                    <ValidationProvider name="Nama lengkap" rules="required" v-slot="{ errors }">
                      <input 
                        v-model="data.nama_lengkap"
                        @keypress="isLetterOrNumber($event)"
                        class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                        type="text" 
                        autocomplete="off"
                        placeholder="Masukkan nama lengkap">
                      <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                  <label class="text-16 leading-21 text-primary-dark">
                  No WhatsApp
                  </label>
                  <div class="mb-4">
                    <ValidationProvider class="relative" name="Nomor whatsapp" rules="required|phone_format|minimal" v-slot="{ errors }">
                      <input 
                        v-model="data.no_whatsapp"
                        @keypress="isNumber($event)"
                        class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-4 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                        type="text" 
                        inputmode="numeric"
                        pattern="[0-9]+"
                        autocomplete="off"
                        placeholder="Masukkan no WhatsApp">
                      <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>

                </div>

                <label class="text-16 leading-21 text-primary-dark">
                Odometer
                </label>
                <div class="mb-4">
                  <ValidationProvider name="Odometer" rules="required" v-slot="{ errors }">
                    <input 
                      v-model="data.odometer"
                      @keypress="isNumber($event)"
                      class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                      type="text" 
                      autocomplete="off"
                      placeholder="Masukkan jarak tempuh kendaraan">
                    <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>

              </div>
              <div class="grid text-center bg-white px-4">
                <button 
                  :disabled="loading"
                  class="bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 px-20 rounded-4 inline-block focus:outline-none focus:shadow-outline" 
                  type="submit">
                  <span v-if="loading">Proses...</span>
                  <span v-else>Print Registrasi</span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>

      </div>  

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { extend } from 'vee-validate';
import regex from "../../libs/regex";
const successImg = require("@/assets/icons/tick-square.png");

extend("phone_format", {
  params: ["length", "message"],
  validate(val, { message }) {
    const format = val.substring(0, 1)
    const format1 = val.substring(0, 2)
    const format2 = val.substring(0, 3)
    const msg = 'Format nomor whastapp tidak sesuai'

    if(val.length == 1) {
      if(format == '+' || format1 == '0') {
        return true;
      }else{
        return message ?? msg;
      }
    }else if(val.length == 2) {
      if(format1 == '+6' || format1 == '08') {
        return true;
      }else{
        return message ?? msg;
      }
    }else if(val.length >= 3) {
      if(format2 == '+62' || format1 == '08') {
        return true;
      }else{
        return message ?? msg;
      }
    }else{
      return message ?? msg;
    }
  }
});

extend('minimal', value => {
    if (value.length >= 9) {
        return true;
    }

    return 'Nomor whatsapp minimal 9 angka';
});

export default {
  props:['id'],
  components:{
  },
  name: 'vehicleBooking',
  metaInfo: {
    title: 'Hyundai Service Point',
    titleTemplate: '%s - Filter Vehicle Booking',
  },
  data(){
    return {
      data:{
        police_number:this.id,
        nama_lengkap:"",
        no_whatsapp:"",
        odometer:"",
      },
      booking:null,
      print:null,
      loading:false,
      validates:[]
    }
  },
  watch: {},
  methods: {
    moment: function (date) {
      return moment(date).locale('id');
    },
    getBookingVehicle() {
      const token = localStorage.getItem('jwt');
      if(token) {
        axios.get(`${process.env.VUE_APP_API_URL}api/booking/vehicle?police_number=${this.id}`,
        { headers: 
          { 'access-token': token}
        })
        .then(response => {
          const { data, status } = response.data
          if(status == 200) {
            this.booking = data
            this.data.nama_lengkap = (data.customer_name ? data.customer_name : '')
            this.data.no_whatsapp = (data.customer_phone ? data.customer_phone : '')
            this.data.odometer = (data.odometer ? data.odometer : '')
          }
        })
        .catch(e => {
          // console.log(e.response);
          if(e.response) {
            const {status} = e.response.data
            if(status == 401){
              localStorage.removeItem('jwt');
              localStorage.removeItem('user');
              this.$router.push({
                name: 'login',
              })
            }
          }
        })
      }else{
        this.$router.push('/login')
      }
    },
    handleRegist(){
      
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });

        this.loading = true

        const token = localStorage.getItem('jwt');
        if(token) {

          var formData = new FormData();
          formData.append('police_number', this.data.police_number);
          
          if(this.booking && this.booking.booking_type == "Online Booking") {
            formData.append('is_walk_in', 2);
          }else{
            formData.append('is_walk_in', 1);
            formData.append('customer_name', this.data.nama_lengkap);
            formData.append('customer_phone', this.data.no_whatsapp);
          }
          formData.append('odometer', this.data.odometer);

          axios.post(`${process.env.VUE_APP_API_URL}api/booking/registration`,
          formData,
          { headers: { 
              'Access-Control-Allow-Origin':'*',
              'content-type': `multipart/form-data; boundary=${formData._boundary}`,
              'access-token': token
            }
          })
          .then(response => {
            const { data, status } = response.data
            if(status == 200) {
              this.print = data
              this.data = {police_number: this.id}

              this.$swal({
                imageUrl: successImg,
                html:
                '<h2 class="text-20 leading-30 text-primary-dark font-medium mb-4">Berhasil Print Registrasi</h2>' +
                '<p class="text-16 leading-24 text-placeholder">Registrasi berhasil di print dan sudah otomatis masuk ke dalam sistem kami. </p>',
                customClass: {
                  confirmButton: 'bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 sm:px-52 px-16 rounded-4 inline-block focus:outline-none focus:shadow-outline',
                },
                buttonsStyling: false,
                showCancelButton: false,
                confirmButtonText: 'Tutup',
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.$router.push("/vehicle");
                } else if (result.isDenied) {
                  this.$router.push("/vehicle");
                }else{
                  this.$router.push("/vehicle");
                }
              })
            this.loading = false

            }

          })
          .catch(e => {
            // console.log(e.response);
            if(e.response) {
              const {status} = e.response.data
              if(status == 401){
                localStorage.removeItem('jwt');
                localStorage.removeItem('user');
                this.$router.push({
                  name: 'login',
                })
              }
            }
            this.loading = false
          }).finally(()=>{
            this.$swal({
                imageUrl: successImg,
                html:
                '<h2 class="text-20 leading-30 text-primary-dark font-medium mb-4">Berhasil Print Registrasi</h2>' +
                '<p class="text-16 leading-24 text-placeholder">Registrasi berhasil di print dan sudah otomatis masuk ke dalam sistem kami. </p>',
                customClass: {
                  confirmButton: 'bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 sm:px-52 px-16 rounded-4 inline-block focus:outline-none focus:shadow-outline',
                },
                buttonsStyling: false,
                showCancelButton: false,
                confirmButtonText: 'Tutup',
              })
              .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.$router.push("/vehicle");
                } else if (result.isDenied) {
                  this.$router.push("/vehicle");
                }else{
                  this.$router.push("/vehicle");
                }
              })
              this.loading = false
          })
        }else{
          this.$router.push('/login')
        }
      })
    },
  
    isLetterOrNumber(e) {
      regex.isLetterOrNumber(e)
    },
    isNumber(e) {
      regex.isNumber(e)
    },
  },
  mounted() {
    this.getBookingVehicle();
  }
}
</script>

<style>
  
</style>
