<template>
  <div id="app">
    <div class="max-w-2xl mx-auto min-h-screen shadow-md">
      
      <div class="sm:flex">
        <main class="bg-primary-light-2 w-full">
          <section class="divide-y text-gray-900">    
            <router-view />
          </section>
        </main>
      </div>    

    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
</style>
