import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import VueMeta from 'vue-meta'
import VueSweetalert2 from 'vue-sweetalert2';

import './assets/tailwind.css'
import 'sweetalert2/dist/sweetalert2.min.css';

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import id from "vee-validate/dist/locale/id.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("id", id);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.use(VueSweetalert2);
Vue.use(VueMeta)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
