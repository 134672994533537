import Vue from "vue";
import Router from "vue-router";
import AppLayout from "./layouts/App.vue";
import PublicLayout from "./layouts/Public.vue";
import Booking from './views/Booking/Booking'
import Reservasi from './views/Booking/Reservasi'
import ReservasiDetail from './views/Booking/ReservasiDetail'
import Login from './views/Login'
import Vehicle from './views/Vehicle/Vehicle'
import VehicleBooking from './views/Vehicle/VehicleBooking'
import Survey from './views/Survey/Survey'
import SurveyDetail from './views/Survey/SurveyDetail'
import NotFound from './views/404'
import KebijakanPrivasi from './views/KebijakanPrivasi/Index.vue'

Vue.use(Router);

let router = new Router({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  mode: "history",
  routes: [
    {
      path: "/",
      component: PublicLayout,
      children: [
        {
          path: "",
          name: "booking",
          component: Booking
        }
      ]
    },
    {
      path: "/booking/reservation/:id",
      component: PublicLayout,
      children: [
        {
          props: true,
          path: "",
          name: "reservasiDetail",
          component: ReservasiDetail
        }
      ]
    },
    {
      path: "/reservasi",
      component: PublicLayout,
      children: [
        {
          path: "",
          name: "reservasi",
          component: Reservasi
        }
      ]
    },
    {
      path: "/login",
      component: PublicLayout,
      meta: {
        guest: true
      },
      children: [
        {
          path: "",
          component: Login
        }
      ]
    },
    {
      path: "/survey",
      component: AppLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "survey",
          component: Survey
        }
      ]
    },
    {
      path: "/survey/:id",
      component: AppLayout,
      meta: {
        requiresAuth: true,
        is_doorman: true,
      },
      children: [
        {
          props: true,
          path: "",
          name: "surveyDetail",
          component: SurveyDetail
        }
      ]
    },
    {
      path: "/vehicle",
      component: AppLayout,
      meta: {
        requiresAuth: true,
        is_doorman: true,
      },
      children: [
        {
          path: "",
          name: "vehicle",
          component: Vehicle
        }
      ]
    },
    {
      path: "/vehicle/:id",
      component: AppLayout,
      meta: {
        requiresAuth: true,
        is_doorman: true,
      },
      children: [
        {
          props: true,
          path: "",
          name: "vehicleBooking",
          component: VehicleBooking
        }
      ]
    },
    {
      path: "/privacy-policy",
      component: PublicLayout,
      children: [
        {
          path: "",
          name: "kebijakanPrivasi",
          component: KebijakanPrivasi
        }
      ]
    },
    // catch 404
    {
      path: '/:catchAll(.*)',
      name: 'notFound',
      component: NotFound,
      meta: {
        guest: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some(record => record.meta.is_doorman)) {
        if (user.role == "Doorman") {
          next()
        } else {
          next({ name: 'vehicle' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next()
    } else {
      next({ name: 'vehicle' })
    }
  } else {
    next()
  }
})

export default router
