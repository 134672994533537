<template>
  <div>
    <nav class="bg-white border-grey border-b px-2 sm:px-4 py-2.5 rounded-4">
      <div class="container flex flex-wrap justify-between items-center mx-auto">
        <a href="#" class="flex">
            <img :src="require(`@/assets/hyundai.png`)" class="w-12 mr-3 h-10" alt="hyundai">
        </a>
        <button data-collapse-toggle="mobile-menu" @click="toggle" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-4 md:hidden hover:bg-grey focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
          <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </button>
        <div class="w-full md:block md:w-auto" :class="showNav ? '' : 'hidden'" id="mobile-menu">
          <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li>
              <router-link @click.native="closeNav" to="/vehicle" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-grey md:hover:bg-transparent md:border-0 md:hover:text-primary-light md:p-0 link">Filter Vehicle</router-link>
            </li>
            <li>
              <router-link @click.native="closeNav" to="/survey" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-grey md:hover:bg-transparent md:border-0 md:hover:text-primary-light md:p-0 link">Survey</router-link>
            </li>
            <li>
              <a href="#" @click="logout" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-grey md:hover:bg-transparent md:border-0 md:hover:text-primary-light md:p-0 link">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div>
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
export default {
  name: "AppLayout",
  data(){
    return {
      showNav:false,
    }
  },
  methods: {
    toggle() {
     this.showNav = !this.showNav
    },
    closeNav() {
     this.showNav = false
    },
    logout() {
      localStorage.removeItem('jwt');
      localStorage.removeItem('user');
      this.$router.push({
        path: '/login',
      })
    }
  }
};
</script>

<style>
@media (min-width: 768px) {
  .link.active {
    color: #086dc7 !important;
    background-color: transparent !important;
  }
}
.link.active {
  color: white ;
  background-color: #086dc7 ;
}
/* .link.active {
  background-color: red;
  color: white;
} */
</style>