<template>
  <div class="relative" :class="customclass">
    <img :src="require(`@/assets/banner.jpg`)" class="w-full" alt="banner">
  </div>
</template>

<script>
export default {
  name: 'BannerHeader',
  props: {
      customclass: String,
  },
}
</script>

<style>

</style>
