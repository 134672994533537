<template>
  <div class="max-w-7xl mx-auto text-gray-600">
    <div class="w-full">
      <div class="bg-white px-4 py-4 mb-2">
        <h2 class="text-16 leading-24 text-primary-dark font-medium mb-5">Reservasi</h2>
        <p>
          Booking ID : <span class="font-bold">{{ reservasi && reservasi.id }}</span> <br/> 
          Booking Type : <span class="font-bold"> {{ reservasi && reservasi.booking_type }} </span> <br/> 
          No Police : <span class="font-bold"> {{ reservasi && reservasi.police_number }} </span> <br/> 
          Tanggal Reservasi : <span class="font-bold"> {{ reservasi && reservasi.date }} </span> <br/> 
          Jam Reservasi : <span class="font-bold"> {{ reservasi && reservasi.time }} </span> <br/> <br/> 
          <!-- Call Center : <span class="font-bold"> {{ reservasi && reservasi.call_center }} </span> <br/>  -->
        </p>
      </div>
       <div class="grid text-center bg-white py-5 my-2 px-4">
          <button 
            @click="booking()"
            class="bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 px-20 rounded-4 inline-block focus:outline-none focus:shadow-outline" 
            type="button"> Kembali
          </button>
        </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: 'reservasi',
  metaInfo: {
    title: 'Hyundai Service Point',
    titleTemplate: '%s - Reservasi',
  },
  data() {
    return {
      reservasi:null
    }
  },
  methods: {
    getReservasi() {
      const reservasi = localStorage.getItem('booking')
      if(reservasi) {
        this.reservasi = JSON.parse(reservasi)
      }
    },
    booking() {
      this.$router.push("/");
    }
  },
  mounted() {
    this.getReservasi()
  }
}
</script>

<style>

</style>