<template>
  <div class="max-w-7xl mx-auto font-poppins">
    <div class="w-full">
       <nav class="py-5 px-4 flex items-center bg-white">
          <h3 class="text-20 leading-30 font-medium text-primary-dark">Survey Kepuasan Pelanggan</h3>
        </nav>

      <div class="">
        <div class="bg-white pb-4">
          <BannerHeader/>
        </div>
        
        <div class="">
          <ValidationObserver ref="form">
            <div v-if="validates.length" class="bg-white px-4 py-3">
              <div class="bg-danger-300">
                <ul class="list-disc mx-5 px-4 font-10">
                  <li class="text-danger text-12 leading-18 py-2" v-for="err in validates" :key="err.name">{{ err.message }}</li>
                </ul>
              </div>
            </div>
            <form class="" @submit.prevent="handleSearch">
              <div id="survey" class="bg-white px-4 pb-1">
                <label class="text-16 leading-21 text-primary-dark">
                  Nomor Polisi
                </label>
                <div class="mb-4">
                  <ValidationProvider name="Nomor polisi" rules="required" v-slot="{ errors }">
                    <input 
                      v-model="police_number"
                      @keypress="isLetterOrNumber($event)"
                      class="appearance-none border border-primary-light-2 rounded-4 w-full py-2 px-3 font-normal text-14 leading-24 text-primary-dark focus:outline-none focus:bg-white focus:border-gray-500 mt-2" 
                      type="text" 
                      autocomplete="off"
                      placeholder="Masukkan nomor polisi">
                    <p class="text-danger text-12 leading-18 mt-2">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>
              </div>
            
              <div class="grid text-center bg-white pt-1 pb-20 px-4">
                <button 
                  class="bg-primary hover:bg-primary-light text-white font-medium text-16 leading-24 py-3 px-20 rounded-4 inline-block focus:outline-none focus:shadow-outline" 
                  type="submit">
                  Cari
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>

      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import regex from "../../libs/regex";
import BannerHeader from "../../components/BannerHeader.vue"

export default {
  components:{
    BannerHeader,
  },
  name: 'survey',
  metaInfo: {
    title: 'Hyundai Service Point',
    titleTemplate: '%s - Survey Kepuasan Pelanggan',
  },
  data(){
    return {
      police_number:"",
      errors:[],
      validates:[],
    }
  },
  watch: {},
  methods: {
    handleSearch(){
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });

        const token = localStorage.getItem('jwt');
        if(token) {
          axios.get(`${process.env.VUE_APP_API_URL}api/survey/check-booking-survey?police_number=${this.police_number}`,
          { headers: 
            { 'access-token': token}
          })
          .then(response => {
            const { status } = response.data
            if(status == 200) {
              this.$router.push({
                name: 'surveyDetail',
                params: {
                  id: this.police_number
                }
              })
            }
          })
          .catch(e => {
            if(e.response) {
              const {data, status, message} = e.response.data
              if(status == 422) {
                this.validates = data
              }else if(status == 404 || status == 403) {
                this.validates = [{message}]
              }else if(status == 401){
                localStorage.removeItem('jwt');
                localStorage.removeItem('user');
                this.$router.push({
                  name: 'login',
                })
              }
            }
          })
        }else{
          this.$router.push('/login')
        }
      })
    },
    isLetterOrNumber(e) {
      regex.isLetterOrNumber(e)
    },
  },
  mounted() {
    
  },
}
</script>

<style>
  
</style>