<template>
    <div class="border-2 border-grey rounded-4 mt-2">
        <div v-on:click="isOpen = !isOpen" class="flex justify-between items-center text-14 leading-21 text-primary-dark px-3 py-2 font-normal rounded cursor-pointer">
            <span>{{ time }}</span>
            <div class="flex items-center">
                <span class="mr-3">{{ booking }} </span>
                <div v-if="isOpen">
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.45334 4.81999L7.31334 2.67999L6.00667 1.36666C5.45334 0.813327 4.55334 0.813327 4.00001 1.36666L0.546674 4.81999C0.0933403 5.27333 0.420007 6.04666 1.05334 6.04666H4.79334H8.94667C9.58667 6.04666 9.90667 5.27333 9.45334 4.81999Z" fill="#292D32" fill-opacity="0.5"/>
                    </svg>
                </div>
                <div v-else>
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.94668 0.953369H4.79335H1.05335C0.413349 0.953369 0.0933488 1.7267 0.546682 2.18004L4.00002 5.63337C4.55335 6.1867 5.45335 6.1867 6.00668 5.63337L7.32001 4.32003L9.46002 2.18004C9.90668 1.7267 9.58668 0.953369 8.94668 0.953369Z" fill="#292D32" fill-opacity="0.5"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="py-2 px-3 rounded" :class="isOpen ? 'd-block' : 'hidden'">
            <div class="border border-dashed border-grey bg-white1 rounded px-3">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data: () => {
        return {
            isOpen: false
        }
    },
    name: 'Accordion',
    props: {
        time: String,
        booking: String,
    }
}
</script>