function isNumber(e) {
	let char = String.fromCharCode(e.keyCode)// Get the character
	if(/^[0-9+]+$/.test(char)) return true// Match with regex 
	else e.preventDefault()// If not match, don't add to input text
}

function isEmail(e) {
	let char = String.fromCharCode(e.keyCode)// Get the character
	// eslint-disable-next-line no-useless-escape
	if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(char)) return true// Match with regex 
	else e.preventDefault()// If not match, don't add to input text
}

function isLetterOrNumber(e) {
	let char = String.fromCharCode(e.keyCode)
	if (/^[A-Za-z0-9\s]+$/.test(char)) return true
	else e.preventDefault()
}

export default {isNumber, isEmail, isLetterOrNumber}